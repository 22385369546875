<template>
  <v-app id="root">
    <v-app-bar fixed dark dense color="primary" class="app-header">
      <v-col cols="4" class="text-start">
        <v-toolbar-title v-text="name" />
      </v-col>
      <v-col cols="4" class="text-center">
        <h2 v-if="$route.name == 'Nueva solicitud' || $route.name == 'Editar solicitud'" v-text="$route.name" />
      </v-col>
      <v-col cols="4" class="text-end">
        <User-Menu :userInfo="userData" :roles="roles" :version="version" :img="img" :configure="configureMenu">
          <template v-slot:exit>
            <Basic-Btn :text="configureMenu ? 'Volver' : 'Configuración'" color="disabled" @click="configureMenu = !configureMenu" :loading="loading" v-if="!changePassword" />
            <Basic-Btn text="Cancelar" color="disabled" @click="cancelPassword()" :loading="loading" v-if="changePassword" />
            <Basic-Btn text="Guardar contraseña" color="success" @click="reset()" :loading="loading" v-if="changePassword" />
            <Basic-Btn text="Cerrar sesión" color="error" @click="logout()" :loading="loading" v-if="!configureMenu" />
          </template>
          <template v-slot:configureMenu>
            <v-form ref="formReset" lazy-validation @submit.prevent class="text-center">
              <v-list flat v-if="!changePassword">
                <v-list-item-group v-model="selectMenuAction" color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-image'" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
                      <v-list-item-title v-text="'Cambio de foto de perfil'" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-lock-reset'" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Cambio de contraseña'" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <div v-if="changePassword">
                <h3>Ingrese su nueva contraseña.</h3>
                <br />
                <v-row>
                  <v-col cols="12" class="px-7 py-0">
                    <v-text-field v-model="forms.update.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :rules="rules.password" :type="show.password1 ? 'text' : 'password'" :append-icon="show.password1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show.password1 = !show.password1"  :disabled="loading" outlined dense required autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-7 py-0">
                    <v-text-field v-model="forms.update.password2" label="Confirmar contraseña" prepend-icon="mdi-lock-reset" :rules="rules.password" :type="show.password2 ? 'text' : 'password'" :append-icon="show.password2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show.password2 = !show.password2"  :disabled="loading" outlined dense required autocomplete="off"></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </template>
        </User-Menu>
        <v-btn icon color="white" @click="notify = !notify">
          <v-badge
            :content="notificationData.no_leidos ? notificationData.no_leidos.length : 0"
            :value="notificationData.no_leidos ? notificationData.no_leidos.length : 0"
            color="error"
            overlap
          >
            <v-icon small>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </v-col>
    </v-app-bar>
    <v-hover v-slot:default="{ hover }">
      <v-navigation-drawer fixed permanent dark dense hidden floating color="primary" class="app-sidemenu" :expand-on-hover="!hover">
        <v-list dense nav>
          <template v-for="item in items">
            <template v-if="item.children && (item.role ? (!perrmissionView) : true)">
              <v-list-group :prepend-icon="item.icon" :key="item.title" :value="openSubMenu($route.path, item.children)" color="white">
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>
                <template v-for="(child, i) in item.children">
                  <v-list-item :key="i" :to="item.children[i].path" link>
                    <v-list-item-icon>
                      <v-icon :class="!hover ? 'marginIcon' : ''" v-text="child.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="child.title" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
            <v-list-item v-else-if="item.path == '/solicitud' ? userData.grupos_encargados: true && (item.role ? (!perrmissionView) : true)" color="white" :key="item.title" :to="item.path" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title active>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isOnlyDeliveryRole" v-for="item in itemsDelivery">
            <v-list-item color="white" :to="item.path" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title active>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isOnlyValidatorRole" v-for="item in itemsValidator">
            <v-list-item color="white" :to="item.path" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title active>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <v-list-item v-if="!hover">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <div class="pa-2">
            <v-list-item-subtitle v-if="hover">
              <v-btn block text small elevation="0" :loading="loading" @click.native="logout()">
                Cerrar sesión
                <v-icon right dark>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-subtitle>
          </div>
        </template>
      </v-navigation-drawer>
    </v-hover>

    <v-main class="pt-12 pl-14 pb-0 grey lighten-3">
      <Notification :open="notify" :items="notificationData"></Notification>
      <router-view></router-view>
      <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
    </v-main>
  </v-app>
</template>

<script>
import { UserMenu, BasicBtn, BasicAlert, Notification, } from "@/components";

export default {
  name: "root",
  components: {
    UserMenu,
    BasicBtn,
    BasicAlert,
    Notification,
  },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    logo: require("@/assets/logo.png"),
    username: "",
    roles: [],
    notificationData: {},
    userData: {},
    path: "/api/logout",
    loading: false,
    success: false,
    notify: false,
    isSelecting: false,
    selectedFile: null,
    msgSuccess: "",
    error: false,
    configureMenu: false,
    changePassword: false,
    msgError: "",
    img: "",
    selectMenuAction: 2,
    items: [
      { icon: "mdi-home", title: "Home", path: "/home" },
      {
        icon: "mdi-cog",
        title: "Configuración",
        role: true,
        children: [
          { icon: "mdi-account", title: "Usuarios", path: "/usuarios" },
          { icon: "mdi-domain", title: "Empresas", path: "/empresas" },
          { icon: "mdi-map-marker-radius-outline", title: "Zonas", path: "/zonas" },
          { icon: "mdi-account-group", title: "Grupos", path: "/grupos" },
          { icon: "mdi-cash-multiple", title: "Globales", path: "/configuraciones-globales" },
        ],
      },
      {
        icon: "mdi-cash",
        title: "Pagos",
        role: true,
        children: [
          { icon: "mdi-cash-check", title: "Autorizar pagos", path: "/pagos-pendientes" },
          { icon: "mdi-handshake", title: "Convenios", path: "/convenios" },
        ],
      },
      { icon: "mdi-account-multiple", title: "Clientes", path: "/clientes" },
      { icon: "mdi-file-outline", title: "Mis solicitudes", path: "/mis-solicitudes" },
      { icon: "mdi-file-plus-outline", title: "Nueva solicitud", path: "/solicitud" },
      {
        icon: "mdi-file-document",
        title: "Reportes",
        role: true,
        children: [
          { icon: "mdi-graph-outline", title: "Reporte de personal", path: "/reporte/personal" },
          { icon: "mdi-file-excel", title: "Reporte de entrega", path: "/reporte/entrega" },
          { icon: "mdi-file-excel", title: "Reporte de pagos semanal", path: "/reporte/pagos-semanal" },
          { icon: "mdi-file-excel", title: "Reporte domiciliario", path: "/reporte/domiciliario" },
          { icon: "mdi-file-excel", title: "Preanálisis", path: "/reporte/preanalisis" },
          { icon: "mdi-file-excel", title: "Hoja 1", path: "/reporte/hoja1" },
        ],
      },
      {
        icon: "mdi-history",
        title: "Historial",
        role: true,
        children: [
          { icon: "mdi-file-clock", title: "Préstamos activos", path: "/historial/prestamos-activos" },
          { icon: "mdi-account-clock", title: "Morosos", path: "/historial/morosos" },
        ],
      },
    ],
    itemsDelivery: [
      { icon: "mdi-cash-check", title: "Autorizar pagos", path: "/pagos-pendientes" },
    ],
    itemsValidator: [
      { icon: "mdi-file-excel", title: "Reporte de pagos semanal", path: "/reporte/pagos-semanal" },
      { icon: "mdi-file-excel", title: "Hoja 1", path: "/reporte/hoja1" },
    ],
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    forms: {
      update: {
        password2: "",
        password: "",
      },
      file: {
        file: "",
      },
    },
    show:{
      password1: false,
      password2: false,
    },
    rules: {
      password: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length >= 8) || "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) => (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
    },
  }),
  mounted() {
    this.check();
    this.getPhoto();
    this.getDataUser();
    this.geNotification()
  },
  watch: {
    selectMenuAction(newValue) {
      if (newValue == 0) {
        this.onButtonClick();
      } else if (newValue == 1) {
        this.changePassword = !this.changePassword;
      }
    },
  },
   computed: {
    perrmissionView: function () {
      this.roles = this.$session.get("roles");
      let value = true;
      for (let rol of this.$session.get("roles")) {
        if (rol == "administrador" || rol == "coordinador general") {
          value = false;
        }
      }
      return value;
    },
    isOnlyDeliveryRole: function () {
      let roles = this.$session.get("roles");
      if (roles.indexOf('entrega') >= 0 && roles.indexOf('administrador') < 0 && roles.indexOf('coordinador general') < 0) {
        return true
      }
      return false;
    },
    isOnlyValidatorRole: function () {
      let roles = this.$session.get("roles");
      if (roles.indexOf('validador') >= 0 && roles.indexOf('administrador') < 0 && roles.indexOf('coordinador general') < 0) {
        return true
      }
      return false;
    }
  },
  methods: {
    async check() {
      this.username = this.$session.get("user");
      this.roles = this.$session.get("roles");
    },
    logout() {
      this.loading = true;
      if (this.$session.exists()) {
        this.axios
          .post(this.host + this.path)
          .then((response) => {
            this.$session.destroy();
            this.$router.push("/login");
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$router.push("/login");
      }
    },
    async getPhoto() {
      this.loading = true;
      this.axios;
      this.axios({
        method: "get",
        url: this.host + "/api/admin/user/photo",
        responseType: "blob",
      })
        .then((response) => {
          var src = URL.createObjectURL(response.data);
          this.img = src;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getDataUser() {
      this.loading = true;
      this.axios
        .get(this.host + "/api/admin/user/profile")
        .then((response) => {
          this.userData = response.data.data;
        })
        .finally(() => {
        });
    },
    reset() {
      this.loading = true;
      let data = {
        password: this.forms.update.password,
      };

      this.axios
        .put(this.host + "/api/admin/user/updatePassword", { data })
        .then(() => {
          this.configureMenu = false;
          this.forms.update.password = "";
          this.forms.update.password2 = "";
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Contraseña actualizada correctamente.";
        })
        .catch((error) => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message;
        })
        .finally(() => {});
    },
    home() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },
    openSubMenu(location, arrayLocation) {
      let open = false;
      for (let i = 0; i < arrayLocation.length; i++) {
        if (location === arrayLocation[i].path) {
          open = true;
          break;
        }
      }
      return open;
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.forms.file.file = e.target.files[0];
      this.updatePhoto();
    },
    updatePhoto() {
      if (this.forms.file.file.size <= 1000000) {
        if (this.forms.file.file.type == "image/png" || this.forms.file.file.type == "png") {
          this.loading = true;
          let info = new FormData();
          for (let key in this.forms.file) {
            info.append(key, this.forms.file[key]);
          }
          this.axios
            .post(this.host + "/api/admin/user/photo", info)
            .then((response) => {
              this.getPhoto();
            })
            .catch((error) => {
              this.alert.active = true;
              this.alert.color = "error";
              this.alert.msg = error;
            })
            .finally(() => {
              this.loading = false;
              this.alert.active = true;
              this.alert.color = "success";
              this.alert.msg = "Imagen actualizada correctamente";
              this.selectMenuAction = 2;
            });
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Formato no valido.";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Documento supera el limite de 1MB.";
      }
    },
    cancelPassword() {
      this.changePassword = !this.changePassword;
      this.selectMenuAction = 2;
    },
    geNotification() {
      this.loading = true;
      this.axios
        .get(this.host + "/api/admin/user/notification")
        .then((response) => {
          this.notificationData = response.data.data;
        })
        .catch((error) => {
          this.$session.destroy();
          this.$router.push("/login");
        })
    },
  },
};
</script>

<style scoped>
  .app-header {
    z-index: 99;
    margin-left: 56px;
  }
  .app-sidemenu {
    z-index: 99;
  }
  .text-normal {
    text-transform: none !important;
  }
  v-btn v-navigation-drawer:hover ~ v-btn {
    width: 2000px !important;
    height: 2000px !important;
  }
  .marginIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .routerMain {
    height: 100vh;
    width: 100vw;
  }
  .routerMainMobile {
    height: 100vh;
    width: 100vw;
  }
</style>
